import React from 'react';
import type { CorePanelItemListSubHeaderProps } from '../PaneledItemList/PaneledItemList';
import { useClassNameMapper } from 'react-bootstrap';
import localStyles from './PaneledItemListSubHeader.module.pcss';
import { PanelType } from '@aurora/shared-client/components/common/Panel/enums';

export interface PaneledItemListSubHeaderProps extends CorePanelItemListSubHeaderProps {
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
}

/**
 * Custom sub-header component for the PaneledItemList
 *
 * @author Willi Hyde, Adam Ayres
 */
const PaneledItemListSubHeader: React.FC<
  React.PropsWithChildren<PaneledItemListSubHeaderProps>
> = ({ as: Component = 'div', className, children, panel }) => {
  const cx = useClassNameMapper(localStyles);

  return (
    <Component
      className={cx(
        className,
        { [`lia-panel-${panel}`]: panel !== PanelType.NONE },
        { 'border-bottom': panel === PanelType.BUBBLE }
      )}
    >
      {children}
    </Component>
  );
};

export default PaneledItemListSubHeader;
