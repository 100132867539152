import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';

interface Props {
  /**
   *
   */
  className?: string;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Children to display inside of the component.
   */
  children?: React.ReactNode;
}

const ListTitle: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  as: Component = 'h5',
  children
}: Props) => {
  const cx = useClassNameMapper();
  return <Component className={cx(className)}>{children}</Component>;
};

export default ListTitle;
